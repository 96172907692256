<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>

<script>
import TablePage from '@/components/tablePage' //表格页面组件
import {
  vipFillList,
  vipFillUpdateStatus,
  vipFillDel,
  vipReverseFill
} from '@/api/vip/bill/fill'

export default {
  name: 'Fill',
  components: { TablePage },
  data () {
    return {
      options: {
        mutiSelect: true, // 多选
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        title: '会员充值列表',
        getListApi: vipFillList,
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: 'billId',
        //搜索
        search: [
          {
            label: '创建时间',
            type: 'datetimerange',
            model: '',
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'month',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: "numberRange",
            label: "充值金额",
            filter: "startFillMoney",
            seniorSearch: true,
            defaultValue: undefined,
            model: undefined,
            filterFnc: ([one, two] = []) => ({
              startFillMoney: one,
              endFillMoney: two
            })
          },
          {
            type: "numberRange",
            label: "赠送金额",
            filter: "startPresentMoney",
            seniorSearch: true,
            defaultValue: undefined,
            model: undefined,
            filterFnc: ([one, two] = []) => ({
              startPresentMoney: one,
              endPresentMoney: two
            })
          },
          this.$select({
            key: 'bill_status',
            label:'方案状态',
            option: {
              seniorSearch: true,
              option:{
                multiple:true,
              }
            }
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        //按钮
        buttons: [
          {
            click: 'add',
            label: '新增',
            icon: 'el-icon-plus',
            type: 'primary'
          },
          {
            click: 'audit',
            label: '审核',
            alertText: '确认要审核选中方案吗？',
            type: 'success',
            icon:'el-icon-document'
          },
          {
            click: 'reverseFill',
            label: '单据反充值',
            type: 'primary',
            icon: '',
            disabled: () => !this.options?.check?.length
          },
          {
            click: 'del',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete',
            disabled: () => !this.options?.check?.length
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh',
            type: ''
          }
        ],
        //表格
        columns: [
          {
            prop: 'billNo',
            label: '单据编号',
            type: 'link',
            click: 'update',
            minWidth: 150,
            align: 'center'
          },
          {
            prop: 'billDate',
            label: '单据日期',
            minWidth: 150,
            align: 'center'
          },
          {
            prop: 'billStatusName',
            label: '单据状态',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'billRemark',
            label: '方案备注',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'auditBy',
            label: '审核人',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'auditTime',
            label: '审核时间',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'updateBy',
            label: '修改人',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'updateTime',
            label: '修改时间',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'createBy',
            label: '创建人',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'createTime',
            label: '创建时间',
            minWidth: 120,
            align: 'center'
          },
        ]
      }
    }
  },
  methods: {
    async handleEvent (type, row) {
      let billIds = []
      switch (type) {
        case 'add':
          this.$router.push({
            name: 'FillDetail'
          })
          break
        case 'update':
          this.$router.push({
            name: 'FillDetail',
            query: {
              billId: row.billId,
              type: 'Update'
            }
          })
          break
        case 'audit':
          this.options.check.forEach(item => {
            if (item.billStatus == '0') billIds.push(item.billId)
          })
          if (billIds.length >= 1) {
            try {
              await vipFillUpdateStatus({
                billIds,
                billStatus: 2
              })
              this.$message.success('审核成功')
              this.$refs.tablePage.getList()
            } catch (err) {}
          } else {
            this.$message.success('请选择未审核的单据')
          }
          break
        case 'reverseAudit':
          this.options.check.forEach(item => {
            if (item.billStatus == '2') billIds.push(item.billId)
          })
          if (billIds.length >= 1) {
            try {
              await vipFillUpdateStatus({
                billIds,
                billStatus: 0
              })
              this.$message.success('反审核成功')
              this.$refs.tablePage.getList()
            } catch (err) {}
          } else {
            this.$message.success('请选择已审核的单据')
          }
          break
        case 'del':
          this.options.check.forEach(item => {
            if (item.billStatus == '0') billIds.push(item.billId)
          })
          if (billIds.length >= 1) {
            try {
              await vipFillDel(billIds)
              this.$message.success('删除成功')
              this.$refs.tablePage.getList()
            } catch (err) {}
          } else {
            this.$message.success('请选择未审核的单据')
          }
          break
        case 'reverseFill':
        this.options.check.forEach(item => {
            if (!item.fillBackBillId && item.billStatus == '2') billIds.push(item.billId)
          })
          if (billIds.length >= 1) {
            try {
              await vipReverseFill(billIds)
              this.$message.success('反充值成功')
              this.$refs.tablePage.getList()
            } catch (err) {}
          } else {
            this.$message.success('请选择已审核正常充值的单据')
          }
          break
        default:
          break
      }
    }
  }
}
</script>
